<template>
  <div class="container">
    <TitleBarBaseVue :handle="{ isSave: true, saveText:'添加' }" @handleSave="onAdd" />
    <el-table :data.sync="tableList" border>
      <el-table-column
        label-class-name="header"
        prop="userId"
        label="管理员ID"
        min-width="200"
        align="center"
      ></el-table-column>
      <el-table-column prop="trueName" label="姓名" min-width="300" align="center"></el-table-column>
      <el-table-column prop="phone" label="手机号" min-width="200" align="center"></el-table-column>
      <el-table-column label="修改密码" min-width="200" align="center">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="handleUpdatePwd(scope.row.userId)">修改密码</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="添加时间" min-width="200" align="center">
        <template slot-scope="scope">{{ scope.row.createTime | dateformat('YYYY-MM-DD HH:mm:ss') }}</template>
      </el-table-column>
    </el-table>
    <div class="pageCo">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="params.pageNo"
        :page-sizes="[1, 2, 5, 10, 20]"
        :page-size="params.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      />
    </div>

    <!-- 添加管理员  -->
    <el-dialog title="添加" v-if="editVisible" :visible.sync="editVisible" width="600px">
      <div class="margin-right-10">
        <el-form label-position="right" label-width="80px">
          <el-form-item label="姓名" class="is-required">
            <el-input v-model="dtaTaAs.trueName"></el-input>
          </el-form-item>
          <el-form-item label="手机号" class="is-required">
            <el-input v-model="dtaTaAs.phone"></el-input>
          </el-form-item>
          <el-form-item label="密码" class="is-required">
            <el-input v-model="dtaTaAs.loginPwd"></el-input>
          </el-form-item>
          <el-form-item label="头像" class="is-required">
            <upload-place v-model="dtaTaAs.profilePic" />
            <div class="share_desc">
                仅支持JPG、JPEG、BMP、PNG格式的图片（建议使用200*200px的规格）
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer margin-right-10">
        <el-button @click="editVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleAdd">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import TitleBarBaseVue from '@/components/TitleBar/TitleBarBase.vue'
import uploadPlace from '@/components/upload/uploadPlace.vue'
import { adminList, updatePwd, adminAdd } from '@/api/AdminController'
export default {
  data() {
    return {
      tableList: [],
      params: {
        pageNo: 1,
        pageSize: 10
      },
      total: 0,

      editVisible: false, // 修改弹框
      dtaTaAs: {
        trueName: "",
        phone: "",
        loginPwd: "",
        profilePic: ""
      },
    }
  },
  mounted() {
    this.getAdminList()
  },
  methods: {
    async getAdminList() {
      let [err, res] = await adminList(this.params)
      if (err) {
        console.log(err)
        return this.$message.error(err.msg || '获取用户列表失败')
      }
      this.tableList = res.data.records
      this.total = res.data.total
    },
    // 监听 pageSize 发生改变
    handleSizeChange(newSize) {
      this.params.pageSize = newSize
      this.getAdminList()
    },
    // 监听 页码值 发生改变
    handleCurrentChange(newPage) {
      this.params.pageNo = newPage
      this.getAdminList()
    },

    async handleUpdatePwd(userId) {
      const promptResult = await this.$prompt('请输入您修改的密码', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /\S/,
        inputErrorMessage: '不能为空哦'
      }).catch(err => err)
      if (promptResult.action == 'confirm') {
        let [err, res] = await updatePwd({ userId, pwd: promptResult.value })
        if (err) {
          console.log(err)
          return this.$message.error(err.msg || '修改失败')
        }
        console.log(res)
        this.$message.success(res.msg || '修改成功')
      }
    },

    //添加
    onAdd() {
      this.dtaTaAs = {
        trueName: "",
        phone: "",
        loginPwd: "",
        profilePic: ""
      }
      this.editVisible = true
    },

    //添加管理员
    async handleAdd() {
      let {trueName, phone, loginPwd, profilePic} = this.dtaTaAs
      if (trueName && phone && loginPwd && profilePic){

        let [err, res] = await adminAdd(this.dtaTaAs)
        if (err) {
          console.log(err)
          return this.$message.error(err.msg || '添加失败')
        }
        //console.log(res)
        this.$message.success(res.msg || '添加成功')
        this.editVisible = false
        this.getAdminList()
      }else {
        this.$message.error('请填写管理员信息')
      }
      
    },

  },
  components: {
    TitleBarBaseVue,
    uploadPlace
  }
}
</script>

<style lang="less" scoped>
.container {
  padding: 10px;

  .handle_bar {
    margin-bottom: 10px;
  }

  .pageCo {
    width: 100%;
    margin-top: 15px;
    display: flex;
    justify-content: center;
  }
}
</style>
